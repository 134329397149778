import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { filter, first, map, mergeMap, Observable } from 'rxjs';
import { ErrorDialogComponent } from '../../dialogs/error-dialog/error-dialog.component';
import { RequestChangesDialogComponent } from '../../dialogs/request-changes-dialog/request-changes-dialog.component';
import { ApprovalDialogComponent } from '../../dialogs/approval-dialog/approval-dialog.component';
import { SuccessDialogComponent } from '../../dialogs/success-dialog/success-dialog.component';
import { ManifestResponse } from '../../interfaces/manifest-response';
import { Sample } from '../../interfaces/sample';
import { AssetScaleHeightPipe } from '../../pipes/asset-scale-height.pipe';
import { AssetScalesService } from '../../services/asset-scales.service';
import { ManifestService } from '../../services/manifest.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-manifest',
    templateUrl: './manifest.component.html',
    styleUrls: ['./manifest.component.scss'],
})
export class ManifestComponent implements OnInit {
    @ViewChildren('frameWrap') public readonly frameWraps: QueryList<ElementRef<HTMLElement>> | undefined;
    @ViewChildren('iframe') public readonly iframes: QueryList<ElementRef<HTMLIFrameElement>> | undefined;

    public readonly manifest$: Observable<ManifestResponse> = this.route.data.pipe(
        map((data) => data['manifest']),
    );

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly scale: AssetScalesService,
        private readonly viewport: ViewportScroller,
        private readonly scaleHeight: AssetScaleHeightPipe,
        private readonly dialog: MatDialog,
        private readonly manifests: ManifestService,
    ) {
        //
    }

    public ngOnInit(): void {
        this.route.data.pipe(
            first(),
            map((data) => data['manifest']),
            map((data) => data['message']),
            filter((m) => m),
        ).subscribe((message) => {
            this.dialog.open(ErrorDialogComponent, {
                data: message,
                width: '600px',
            });
        });
    }

    public onLoad(index: number, sample: Sample): void {
        if (index < 0) {
            return;
        }

        if (sample.type === 'sms') {
            this.handleSMS(index);
        }

        const iframe = this.iframes?.get(index);
        const frameWrap = this.frameWraps?.get(index);
        if (!iframe || !frameWrap) {
            return;
        }

        const template = this.contentDocument(index).body.getElementsByClassName('template-wrapper')[0] as HTMLElement;
        if (!template) {
            return;
        }

        const height = Math.round(template.offsetHeight * this.scale.scale(sample.type));
        iframe.nativeElement.style.height = height + 'px';
        frameWrap.nativeElement.style.height = (this.scaleHeight.transform(sample, height, 1000) + (46.5 * 2)) + 'px';
    }

    public onAnchor(type: string): void {
        this.viewport.scrollToAnchor(type);
    }

    public onApproveChanges(): void {
        this.dialog.open(ApprovalDialogComponent, {
            width: '600px',
        }).afterClosed().pipe(
            filter((val) => val),
            mergeMap((data) => {
                return this.route.paramMap.pipe(map((p) => ({ token: p.get('token'), data: data })));
            }),
            mergeMap((data) => this.manifests.approve(data.token || '', data.data)),
        ).subscribe(() => this.dialog.open(SuccessDialogComponent, { width: '500px' }));
    }

    public onRequestChanges(): void {
        this.dialog.open(RequestChangesDialogComponent, {
            width: '600px',
        }).afterClosed().pipe(
            filter((val) => val),
            mergeMap((data) => {
                return this.route.paramMap.pipe(map((p) => ({ token: p.get('token'), data: data })));
            }),
            mergeMap((data) => this.manifests.deny(data.token || '', data.data))
        ).subscribe(() => this.dialog.open(SuccessDialogComponent, { width: '500px' }));
    }

    private contentDocument(index: number): Document {
        const iframe = this.iframes?.get(index);
        if (!iframe || !iframe.nativeElement.contentDocument) {
            throw new Error('iframe with index ' + index + ' could not be found');
        }

        return iframe.nativeElement.contentDocument;
    }

    private handleSMS(index: number): void {
        const template = this.contentDocument(index);
        if (!template) {
            throw new Error('template not found for sms [' + index + ']');
        }

        const removeImage = template.getElementById('sms-remove-image');
        if (removeImage) {
            removeImage.remove();
        }

        const addImage = template.getElementById('sms-add-image');
        if (addImage) {
            addImage.remove();
        }

        const message = template.getElementsByClassName('sms-content')[0];
        if (!message) {
            return;
        }

        const blueMessage = template.getElementById('sms-image-container');
        if (!blueMessage) {
            throw new Error('Invalid sms template');
        }

        blueMessage.innerText = message.textContent || '';
    }
}
