import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.scss']
})
export class ApprovalDialogComponent {
    public readonly form: FormGroup = this.forms.group({
        email: ['', [Validators.required, Validators.email]],
    });

    public constructor(
        private readonly forms: FormBuilder,
    ) {
        //
    }
}
