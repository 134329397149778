<div class="container" *ngIf="manifest$ | async as manifest">
    <div class="d-flex">
        <nav>
            <img class="logo" [alt]="manifest.config.name" [src]="manifest.config.baseUrl + '/' + manifest.config.logo">

            <ul>
                <li *ngFor="let sample of manifest.samples" [class.text-uppercase]="sample.type === 'sms'">
                    <a (click)="onAnchor(sample.type)">{{sample.type | assetDisplay}}</a>
                </li>
                <hr>
                <li>
                    <a (click)="onAnchor('approval')">Approval</a>
                </li>
            </ul>
        </nav>
        <div style="margin-left: 200px">
            <div class="page-content">
                <h1 class="mb-0">{{manifest.campaign.title}}</h1>
                <p class="mt-0 campaign-details">Campaign #{{manifest.campaign.id}} Manifest</p>
                <p *ngIf="manifest.campaign.send_email" class="mt-0 subject"><strong>Subject:</strong> {{manifest.campaign.subject}}</p>
            </div>

            <div class="page-content approval-buttons pt-0">
                <button mat-flat-button color="warn" class="me-2" (click)="onRequestChanges()">Request Changes</button>
                <button mat-flat-button color="primary" (click)="onApproveChanges()">Approve Assets</button>
            </div>

            <div class="manifest-content">
                <ng-container *ngFor="let sample of manifest.samples; let i = index">
                    <div class="manifest-wrap bg-white" [id]="sample.type">
                        <h4 class="text-center text-capitalize mb-4">{{sample.type | assetDisplay}}</h4>

                        <div
                            class="frame-wrap"
                            #frameWrap
                        >
                            <iframe
                                #iframe
                                (load)="onLoad(i, sample)"
                                [style.left.px]="(sample | wrapScale:1000) !== 'scale(1)' ? 40 : 0"
                                [style.transform]="sample | wrapScale:1000"
                                [style.width.px]="sample | assetScale:'width'"
                                [style.height.px]="sample | assetScale:'height'"
                                [srcdoc]="sample.markup | injectStyles:sample.type | bypassSecurity"
                            ></iframe>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="page-content approval-buttons" id="approval">
                <button mat-flat-button color="warn" class="me-2" (click)="onRequestChanges()">Request Changes</button>
                <button mat-flat-button color="primary" (click)="onApproveChanges()">Approve Assets</button>
            </div>
        </div>
    </div>
</div>
